html {
    scroll-behavior: smooth;
}

.section-shaped .shape-orange, .section-shaped .shape-style-1.shape-orange {
    background: linear-gradient(150deg, #f4511e 15%, #e64a19 70%, #d84315 94%);
}

.form-control-error {
    border: 1pt solid var(--danger) !important;
    border-color: var(--danger) !important;
}

.rdtPicker {
    min-width: 300px;
}

.rdt {
    input[readonly] {
        background-color: $input-bg !important;
    }

    input[disabled] {
        background-color: $input-disabled-bg !important;
    }
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $orange !important;
    background-color: $orange !important;
}
// stylelint-disable no-duplicate-selectors, selector-no-qualifying-type

//
// Grid examples
//

.ct-example-row {
    .row {
        > .col,
        > [class^="col-"] {
            span {
                display: block;
                padding: .75rem;
                color: rgb(57, 63, 73);
                background-color: rgb(255, 255, 255);
                box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 16px;
                font-size: $font-size-sm;
                border-radius: .25rem;
                margin: 1rem 0;
            }
        }
    }

    .no-gutters {
        > .col,
        > [class^="col-"] {
            span {
                border-radius: 0;
            }
        }
    }

    .flex-items-top,
    .flex-items-middle,
    .flex-items-bottom {
        min-height: 6rem;
        background-color: rgba(255, 0, 0, .1);
    }
}

.ct-example-row-flex-cols .row {
    min-height: 10rem;
    background-color: rgba(255, 0, 0, .1);

    & + .row {
        margin-top: 1rem;
    }
}

.ct-highlight {
    background-color: rgba($ct-primary, .15);
    border: 1px solid rgba($ct-primary, .15);
}

// Grid mixins
.example-container {
    width: 800px;
    @include make-container();
}

.example-row {
    @include make-row();
}

.example-content-main {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
        @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
        @include make-col(8);
    }
}

.example-content-secondary {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
        @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
        @include make-col(4);
    }
}


//
// Container illustrations
//

.ct-example-container {
    min-width: 16rem;
    max-width: 25rem;
    margin-right: auto;
    margin-left: auto;
}

.ct-example-container-header {
    height: 3rem;
    margin-bottom: .5rem;
    background-color: lighten($blue, 50%);
    border-radius: .25rem;
}

.ct-example-container-sidebar {
    float: right;
    width: 4rem;
    height: 8rem;
    background-color: lighten($blue, 25%);
    border-radius: .25rem;
}

.ct-example-container-body {
    height: 8rem;
    margin-right: 4.5rem;
    background-color: lighten($ct-primary, 25%);
    border-radius: .25rem;
}

.ct-example-container-fluid {
    max-width: none;
}


//
// Docs examples
//

.ct-example {
    position: relative;
    margin: 1rem (-$grid-gutter-width / 2) 0;
    @include clearfix();

    @include media-breakpoint-up(sm) {
        margin-right: 0;
        margin-left: 0;
    }

    + .highlight,
    + .clipboard + .highlight {
        margin-top: 0;
    }

    + p {
        margin-top: 2rem;
    }

    .pos-f-t {
        position: relative;
        margin: -1rem;

        @include media-breakpoint-up(sm) {
            margin: -1.5rem;
        }
    }

    .custom-file-input:lang(es) ~ .custom-file-label::after {
        content: "Elegir";
    }

    > .form-control {
        + .form-control {
            margin-top: .5rem;
        }
    }

    > .nav + .nav,
    > .alert + .alert,
    > .navbar + .navbar,
    > .progress + .progress,
    > .progress + .btn,
    .badge,
    .btn {
        margin-top: .5rem;
        margin-bottom: .5rem;
    }

    .btn-group {
        margin-top: .5rem;
        margin-bottom: .5rem;

        .btn {
            margin: 0;
        }
    }

    .alert {
        margin: 0;

        + .alert {
            margin-top: 1.25rem;
        }
    }

    .badge {
        margin-right: .5rem;
    }

    > .dropdown-menu:first-child {
        position: static;
        display: block;
    }

    > .form-group:last-child {
        margin-bottom: 0;
    }

    > .close {
        float: none;
    }
}

// Typography
.ct-example-type {
    .table {
        .type-info {
            color: #999;
            vertical-align: middle;
        }

        td {
            padding: 1rem 0;
            border-color: #eee;
        }

        tr:first-child td {
            border-top: 0;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0;
    }
}

// Contextual background colors
.ct-example-bg-classes p {
    padding: 1rem;
}

// Images
.ct-example > img {
    + img {
        margin-left: .5rem;
    }
}

// Buttons
.ct-example {
    > .btn-group {
        margin-top: .25rem;
        margin-bottom: .25rem;
    }

    > .btn-toolbar + .btn-toolbar {
        margin-top: .5rem;
    }
}

// Forms
.ct-example-control-sizing select,
.ct-example-control-sizing input[type="text"] + input[type="text"] {
    margin-top: .5rem;
}

.ct-example-form .input-group {
    margin-bottom: .5rem;
}

.ct-example > textarea.form-control {
    resize: vertical;
}

// List groups
.ct-example > .list-group {
    max-width: 400px;
}

// Navbars
.ct-example {
    .fixed-top,
    .sticky-top {
        position: static;
        margin: -1rem -1rem 1rem;
    }

    .fixed-bottom {
        position: static;
        margin: 1rem -1rem -1rem;
    }

    @include media-breakpoint-up(sm) {
        .fixed-top,
        .sticky-top {
            margin: -1.5rem -1.5rem 1rem;
        }
        .fixed-bottom {
            margin: 1rem -1.5rem -1.5rem;
        }
    }
}

// Pagination
.ct-example .pagination {
    margin-top: .5rem;
    margin-bottom: .5rem;
}

// Example modals
.modal {
    z-index: 1072;

    .tooltip,
    .popover {
        z-index: 1073;
    }
}

.modal-backdrop {
    z-index: 1071;
}

.ct-example-modal {
    background-color: #fafafa;

    .modal {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        z-index: 1;
        display: block;
    }

    .modal-dialog {
        left: auto;
        margin-right: auto;
        margin-left: auto;
    }
}

// Example tabbable tabs
.ct-example-tabs .nav-tabs {
    margin-bottom: 1rem;
}

// Popovers
.ct-example-popover-static {
    padding-bottom: 1.5rem;
    background-color: #f9f9f9;

    .popover {
        position: relative;
        display: block;
        float: left;
        width: 260px;
        margin: 1.25rem;
    }
}

// Tooltips
.tooltip-demo a {
    white-space: nowrap;
}

.ct-example-tooltip-static .tooltip {
    position: relative;
    display: inline-block;
    margin: 10px 20px;
    opacity: 1;
}

// Scrollspy demo on fixed height div
.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: .5rem;
    overflow: auto;
}

.scrollspy-example-2 {
    position: relative;
    height: 350px;
    overflow: auto;
}

.ct-example-border-utils {
    [class^="border"] {
        display: inline-block;
        width: 5rem;
        height: 5rem;
        margin: .25rem;
        background-color: #f5f5f5;
    }
}

.ct-example-border-utils-0 {
    [class^="border"] {
        border: 1px solid $border-color;
    }
}

//
// Code snippets
//

.highlight {
    padding: 0;
    margin-top: 1rem;
    //margin-bottom: 3rem;
    //background-color: $gray-100;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    @include media-breakpoint-up(sm) {
        padding: 0;
    }
}

.ct-content .highlight {
    margin-right: (-$grid-gutter-width / 2);
    margin-left: (-$grid-gutter-width / 2);

    @include media-breakpoint-up(sm) {
        margin-right: 0;
        margin-left: 0;
    }
}

//
// Component-Code tabs
//

.ct-example {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $gray-200;

    .tab-content {
        .tab-example-result {
            background-color: #f5f7f9;
            border: 1px solid #e6ecf1;
            padding: 1.25rem;
            border-radius: .25rem;
        }
    }

    .nav-tabs-code {
        margin-bottom: .375rem;

        .nav-link {
            font-size: .875rem;

            &:active,
            &.active {
                color: $ct-primary;
            }
        }
    }
}

// Icon examples
.icon-examples {
    margin-top: 1rem;
}

.btn-icon-clipboard {
    margin: 0px;
    padding: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    color: rgb(57, 63, 73);
    background-color: rgb(248, 249, 250);
    border-radius: 4px;
    border: 0px none;
    text-align: left;
    font-family: inherit;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    -moz-appearance: none;
    cursor: pointer;
    width: 100%;
    margin: .5rem 0;

    &:hover {
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 16px;
    }

    > div {
        align-items: center;
        display: flex;
    }

    i {
        box-sizing: content-box;
        color: rgb(57, 63, 73);
        vertical-align: middle;
        font-size: 1.5rem;
    }

    span {
        display: inline-block;
        font-size: .875rem;
        line-height: 1.5;
        color: rgb(57, 63, 73);
        margin-left: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
}
